import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import moment from 'moment';
import { RichText } from 'prismic-reactjs';

import { Layout, SEO, Grid, Box, Text } from 'components';

const Title = Text.withComponent('h2');

const TextContainer = styled(Text.withComponent('div'))`
  h2,
  h3,
  h4 {
    text-align: center;
    text-transform: uppercase;
    margin: 8rem 0 2rem;
  }

  p {
    margin-bottom: 1.5em;
  }
`;

const About = ({ data }) => {
  const page = data.prismic.allPrivacy_policys.edges.slice(0, 1).pop().node;

  const metaData = {
    title: page.meta_title ? RichText.asText(page.meta_title) : null,
    description: page.meta_description
      ? RichText.asText(page.meta_description)
      : null
  };

  if (page.meta_image) {
    metaData.image = page.meta_image.url;
    metaData.meta = [
      {
        property: `og:image:width`,
        content: page.meta_image.dimensions.width
      },
      {
        property: `og:image:height`,
        content: page.meta_image.dimensions.height
      }
    ];
  }

  return (
    <Layout>
      <SEO {...metaData} />

      <Grid>
        <Box gridColumn={['2 / -2', '3 / -3']} mt={[5, 5, 5, 8]}>
          <Title size="2x" textAlign="center">
            {RichText.asText(page.title)}
          </Title>
          <Text color="blackDimmed" textAlign="center" mb={5}>
            Updated {moment(page.changed_date).format('MMM D, YYYY')}
          </Text>

          <TextContainer mb={7}>{RichText.render(page.text)}</TextContainer>
        </Box>
      </Grid>
    </Layout>
  );
};

export default About;

export const query = graphql`
  {
    prismic {
      allPrivacy_policys {
        edges {
          node {
            title
            changed_date
            text
            meta_title
            meta_description
            meta_image
          }
        }
      }
    }
  }
`;
